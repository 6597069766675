import { useMemo } from 'react'
import * as _ from 'lodash';
import { mergePrismicPreviewData } from 'gatsby-source-prismic'

// Returns true if we're in a browser, false otherwise. This will help guard
// against SSR issues when building the site.
const IS_BROWSER = typeof window !== 'undefined'

/**
 * Prismic preview hook
 * @param {object} staticData Data object from Gatsby page
 */
export default function usePreviewData(staticData, prismicType, dataOnly = true, massageFn = (x) => x) {
  return useMemo(() => {
    // If we're not in a browser (i.e. we're in SSR) or preview data has not been
    // set, use the non-preview static data.
    if (!IS_BROWSER || !window.__PRISMIC_PREVIEW_DATA__) {
      return staticData
    }

    if (!window.__PRISMIC_PREVIEW_DATA__[prismicType]) {
      return staticData
    }

    let previewData = window.__PRISMIC_PREVIEW_DATA__[prismicType];

    if (dataOnly) {
      previewData = previewData.data;
    }

    if (massageFn) {
      previewData = massageFn(previewData);
    }

    try {
      return mergePrismicPreviewData({
        staticData,
        previewData,
      });
    } catch (e) {
      return _.merge(staticData, previewData);
    }
  }, [staticData, prismicType, dataOnly, massageFn])
}